import React, { useEffect, useState } from "react";
import { Dialog, IconButton, DialogTitle, } from "@mui/material";
import CustomButton from "../../CustomButton/CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import NovoAcessoModal from "../NovoAcessoModal/NovoAcessoModal";
import api from "../../../api/api";
import Backdrop from "../../Backdrop/Backdrop"
import { ModalContent } from "./style";
import ClientAccessTable from "../../ClientAccessTable/ClientAccessTable";
import SearchBar from "../../SearchBar/SearchBar"

const ManageAccessModal = ({ open, handleClose, userEmail, companyId, ...props }) => {
    const [loading, setLoading] = useState(false);
    const [openNewAccess, setOpenNewAccess] = useState(false);
    const [clientsList, setClientsList] = useState(null);
    const [filteredClientsList, setFilteredClientsList] = useState(null);

    const getAccessList = () => {
        api.get(`/Client/GetAllClientsByCompanyId/${companyId}`)
            .then((response) => {
                return response.data;
            }).then((data) => {
                setClientsList(data)
                setFilteredClientsList(data)
            }).catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        getAccessList()
    }, [])

    
    const filterAccessList = (e) => {
        const searchTerm = e.target.value.toLowerCase();

        const filteredList = clientsList.filter((item) =>
            item.name?.toLowerCase().includes(searchTerm) ||
            item.email?.toLowerCase().includes(searchTerm) 
        );

        if (e.target.value === '' || e.target.value === undefined) {
            setFilteredClientsList(clientsList);
        } else {
            setFilteredClientsList(filteredList)
        }
    }

    return (
        <Dialog
            PaperProps={{
                sx: { borderRadius: '10px', padding: '20px', gap: '20px', minWidth: '350px', width: '50%' }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle sx={{ padding: 0 }} className="title-large">
                Gerenciar Acessos
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000"
                })}
            >
                <CloseIcon />
            </IconButton>

            <ModalContent>
                <div className="acessos-header">
                    <CustomButton onClick={() => { setOpenNewAccess(true) }} variant='outlined'>
                        <span className="title-small">+ Novo acesso</span>
                    </CustomButton>
                    <SearchBar placeholder={"Pesquise um usuário..."} sx={{ width: "60%" }} variant={"outlined"} onChange={filterAccessList} />
                </div>
                <ClientAccessTable
                    getAccessList={getAccessList} clientsList={filteredClientsList} />
            </ModalContent>

            {openNewAccess &&
                <NovoAcessoModal loading={loading} setLoading={setLoading} open={openNewAccess} handleClose={() => { setOpenNewAccess(false) }} getAccessList={
                    getAccessList} />}
            {loading && <Backdrop open={loading} />}
        </Dialog>
    )
}

export default ManageAccessModal;