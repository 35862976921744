import styled from "styled-components";
import { DialogContent } from "@mui/material";

export const ModalContent = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 !important; 
    gap: 15px;

    .acessos-header{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

` 