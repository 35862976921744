import React, { useState } from "react";
import { Dialog, Fab, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import CustomButton from "../../CustomButton/CustomButton";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import api from "../../../api/api";
import Backdrop from "../../Backdrop/Backdrop";
import { SuccessFeedback } from "../../SuccessFeedback/SuccessFeedback";
import { ErrorFeedback } from "../../ErrorFeedback/ErrorFeedback";

const InativateUserModal = ({ open, client, getAccessList, handleClose }) => {
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [isSuccess, setIsSuccess] = useState("INITIAL");

    const handleInativateUser = () => {
        var inativateUser = '/Client/InativateClient';

        var formData = {
            id: client.id
        }

        api.post(inativateUser, formData).then((response) => {
            return response.data;
        }).then((data) => {
            if (data === "Cliente inativado.") {
                setIsSuccess("SUCCESS");
                getAccessList();
                setTimeout(() => {
                    handleClose();
                }, 1500);
            }
        }).catch((error) => {
            setIsSuccess("ERROR");
            console.log(error)
        })
    }

    return (
        <Dialog
            PaperProps={{
                sx: { borderRadius: '10px', padding: '20px', gap: '20px', width: '35%' }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {isSuccess === 'INITIAL' && <>
                <DialogTitle sx={{ padding: 0, gap: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} className="title-large">
                    <Fab color="warning" disableFocusRipple disableRipple disableTouchRipple size="small"
                        sx={{ boxShadow: 'none', cursor: 'initial', color: "#fff", ":active": { boxShadow: "none" }, display: 'block' }}>
                        <ReportProblemOutlinedIcon />
                    </Fab>
                    Atenção!
                </DialogTitle>

                <DialogContent sx={{ padding: 0 }}>
                    <p className="body-large">
                        Com esta ação, <span className="bold"> {client.name} </span> perderá o acesso á essa empresa.
                    </p>
                </DialogContent>

                <DialogActions sx={{ padding: 0 }}>
                    <CustomButton onClick={handleClose} variant={"outlined"} fullWidth>
                        <span className="title-small">Cancelar</span>
                    </CustomButton>
                    <CustomButton onClick={handleInativateUser} variant={"contained"} fullWidth>
                        <span className="title-small">
                            Excluir
                        </span>
                    </CustomButton>
                </DialogActions></>}
            {isSuccess === 'SUCCESS' && <SuccessFeedback feedbackText={"Cliente desativado com sucesso!"} />}
            {isSuccess === 'ERROR' && <ErrorFeedback feedbackText={"Erro ao desativar cliente. "} />}

            <Backdrop open={openBackdrop} />
        </Dialog>
    )
}

export default InativateUserModal;