import React, { useState, useContext } from "react";
import CustomButton from "../../CustomButton/CustomButton";
import TextFieldComp from '../../TextFieldComp/TextFieldComp'
import { DialogModal, ModalTitle, ModalContent, ModalActions, AccessForm } from "./style"
import api from "../../../api/api";
import { useParams } from "react-router-dom";
import { CompanyContext } from "../../../contexts/CompanyContext";
import { ErrorFeedback } from "../../ErrorFeedback/ErrorFeedback";
import { SuccessFeedback } from "../../SuccessFeedback/SuccessFeedback";
import Backdrop from "../../Backdrop/Backdrop";

const NovoAcessoModal = ({ open, handleClose, getAccessList, setLoading, loading }) => {
    const { companyName } = useParams();
    const { getIdFromName } = useContext(CompanyContext);
    const [userForm, setUserForm] = useState({
        email: '',
        role: "Customer",
        clientName: '',
    });
    const [isSuccess, setIsSuccess] = useState("INITIAL");

    const handleCreateUserWithNoPassword = (e) => {
        e.preventDefault()
        var createUserWithNoPassword = '/Authentication/CreateUserWithNoPassword'
        setLoading(true);
        api.post(createUserWithNoPassword, userForm).then((response) => {
            return response.data;
        }).then((data) => {
            handlePromoteUserToClient()
        }).catch((error) => {
            console.log(error)
        })
    }

    const handlePromoteUserToClient = () => {
        setIsSuccess("LOADING");
        var promoteUserToClient = '/Client/PromoteUserToClient'
        var formData = {
            name: userForm.clientName,
            companyId: getIdFromName(companyName),
            userEmail: userForm.email
        }

        api.post(promoteUserToClient, formData).then((response) => {
            return response.data;
        }).then((data) => {
            if (data === "Usuário transformado em cliente com sucesso") {
                setIsSuccess("SUCCESS");
                setTimeout(() => {
                    setLoading(false);
                    getAccessList();
                    handleClose();
                }, 1000);
            }
        }).catch((error) => {
            setIsSuccess("ERROR");
            console.log(error)
        })
    }

    return (
        <DialogModal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalTitle className="title-large">
                Novo Acesso
            </ModalTitle>
            <ModalContent>
                {(isSuccess === "INITIAL" || isSuccess === "LOADING") &&
                    <AccessForm onSubmit={handleCreateUserWithNoPassword}>
                        <TextFieldComp
                            type="text"
                            placeholder={"nome do cliente"}
                            value={userForm.clientName}
                            onChange={(e) => { setUserForm(userForm => ({ ...userForm, ['clientName']: e.target.value })) }}
                            label='Nome'
                            fullWidth={true}
                            required
                        />
                        <TextFieldComp
                            type="email"
                            placeholder={"email do cliente"}
                            value={userForm.email}
                            onChange={(e) => { setUserForm(userForm => ({ ...userForm, ['email']: e.target.value })) }}
                            label='Email'
                            fullWidth={true}
                            required
                        />
                        <ModalActions>
                            <CustomButton sx={{ width: '50%' }} onClick={handleClose} variant={"outlined"}>
                                <span className="title-small">
                                    Cancelar
                                </span>
                            </CustomButton>
                            <CustomButton sx={{ width: '50%' }} type="submit" variant={"contained"}>
                                <span className="title-small">
                                    Salvar
                                </span>
                            </CustomButton>
                        </ModalActions>
                    </AccessForm>}

                <Backdrop open={isSuccess === "LOADING" || loading} />

                {isSuccess === "SUCCESS" &&
                    <SuccessFeedback feedbackText={"Novo acesso adicionado á empresa com sucesso."} />}
                {isSuccess === "ERROR" &&
                    <ErrorFeedback feedbackText={"Erro ao adicionar novo acesso á empresa"} />}
            </ModalContent>


        </DialogModal>
    )
}

export default NovoAcessoModal;