import styled from "styled-components";
import colors from "../../utils/colors.json"

export const BarContainer = styled.div`
    width: 100%;
    height: 20px;
    border-radius: 50px;
    background-color: #e3e3e3;
    border: 1.5px solid #aeaeae;
    padding: 3px 4px;
    box-sizing: border-box;

    .fill{
        width: ${props => props.progress + '%'};
        height: 100%;
        border-radius: 50px;
        background-color: ${colors.toranja};
    }

    .success{
        background-color: ${colors.success};
    }

`