import React, { useState } from 'react';
import { InfoBox, BudgetContainer } from './style'

const BudgetHeader = ({ budget }) => {
    return (
        <BudgetContainer variant={"outlined"}>
            <InfoBox>
                <p className='label-medium'>
                    {budget.createdAt}
                </p>
                <p className='headline-small'>
                    Budget Nº {budget.name}
                </p>
                <p className='label-medium'>
                    criado por {budget.clientEmail} | {budget.companyName}
                </p>
            </InfoBox>

        </BudgetContainer>
    );
};

export default BudgetHeader;
