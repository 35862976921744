import React, { useState, useEffect } from 'react';
import { BudgetContainer, VersionsList, HeaderSection } from './style'
import CustomButton from '../CustomButton/CustomButton';
import BudgetVersionAccordion from '../BudgetVersionAccordion/BudgetVersionAccordion';
import Backdrop from '../Backdrop/Backdrop';
import { getBudgetVersions, createNewBudgetVersion } from '../../api/budgetApi';

const BudgetBody = ({ budget }) => {
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [versions, setVersions] = useState([]);

    const handleGetVersions = async () => {
        var formData = { baseBudgetId: budget.baseBudgetId }
        var message = await getBudgetVersions(formData)

        if (message) {
            setVersions(message)
        }
    }

    const handleCreateNewVersion = async () => {
        setOpenBackdrop(true);
        var formData = { baseBudgetId: budget.baseBudgetId }
        var message = await createNewBudgetVersion(formData)

        if (message === "Nova versão de budget criada com sucesso!") {
            setOpenBackdrop(false);
            handleGetVersions();
        }
    }

    useEffect(() => {
        if (budget) {
            handleGetVersions();
        }
    }, [budget])

    return (
        <BudgetContainer variant='outlined'>
            <HeaderSection>
                <div className='title-container'><p p='title-small'>Versões</p></div>
                <CustomButton variant="contained" onClick={handleCreateNewVersion}>
                    <span className='title-small'>Nova versão +</span>
                </CustomButton>
            </HeaderSection>

            <VersionsList>
                {versions.map((version, i) => (
                    <BudgetVersionAccordion
                        key={i}
                        version={version}
                        openBackdrop={openBackdrop}
                        setOpenBackdrop={setOpenBackdrop}
                        job={budget} />
                ))}
            </VersionsList>

            {openBackdrop &&
                <Backdrop
                    open={openBackdrop}
                    onClick={() => { setOpenBackdrop(false) }}
                />}
        </BudgetContainer>
    );
};

export default BudgetBody;
