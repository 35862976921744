import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { deleteCookie } from '../../utils/methods';
import { logout } from '../../api/userApi';

export default function MenuDrop({ email }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogout = async (e) => {
        setAnchorEl(null);

        var message = await logout()
        if (message.status === 200) {
            deleteCookie("jwt__token")
            window.localStorage.removeItem("user__obj");
            window.location.href = "/"
        }
    };

    const handleClose = (e) => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                sx={{ textTransform: 'lowercase', color: 'black' }}
                className='title-small'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {email}
            </Button>
            <Menu
                sx={{ width: '100%' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem> */}
                <MenuItem
                    className='title-small' onClick={handleLogout}>Sair</MenuItem>
            </Menu>
        </div>
    );
}