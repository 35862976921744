import React, { createContext, useEffect, useState } from 'react';
import api from '../api/api';
import { useLocation } from 'react-router-dom';

export const CompanyContext = createContext();
export const CompanyProvider = ({ children }) => {
    const [companyList, setCompanyList] = useState([]);
    const location = useLocation()

    const getCompanies = () => {
        var allCompaniesURL = '/Company/GetAllCompanies'

        api.get(allCompaniesURL).then((response) => {
            return response.data;
        }).then((data) => {
            setCompanyList(data);
        }).catch((error) => {
        })
    }


    useEffect(() => {
        var auth =
            location.pathname === '/' ||
                location.pathname === '/my-profile' ||
                location.pathname === '/forgot-password' ||
                location.pathname === '/reset-password' ||
                location.pathname === '/finish-register'
                ? false : true;
        if (auth) {
            getCompanies();
        }
    }, [])

    const getIdFromName = (name) => {
        try {
            var obj = companyList.filter((company) => company.name.toLowerCase() === name)
            return obj[0].id
        } catch (error) {
        }
    };

    const getNameFromId = (id) => {
        try {
            var obj = companyList.filter((company) => company.id === id)
            return obj[0].name
        } catch (error) {
        }
    };

    return (
        <CompanyContext.Provider value={{ getIdFromName, companyList, getCompanies, getNameFromId }}>
            {children}
        </CompanyContext.Provider>
    );
};
