import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { DialogModal, ModalTitle, ModalContent, ModalActions } from "../CreateCompanyModal/style"
import ProgressBar from "../../ProgressBar/ProgressBar";
import Backdrop from "../../Backdrop/Backdrop"
import { FeedbackModal } from "../FeedbackModal/FeedbackModal";

const DownloadModal = ({ open, handleClose, progress, error }) => {
    const [downloadStatus, setDownloadStatus] = useState('INIT');
    const [isOpenFeebackModal, setIsOpenFeedbackModal] = useState(false)
    const [feedbackError, setFeedbackError] = useState(error)

    useEffect(() => {
        if (progress === 100) {
            setDownloadStatus("success")
            setIsOpenFeedbackModal(true)
        }
    }, [progress])

    useEffect(() => {
        if (error) {
            setFeedbackError(error)
        }
    }, [error])

    return (
        <DialogModal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                minWidth: '50%'
            }}
        >
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000"
                })}
            >
                <CloseIcon />
            </IconButton>
            <ModalContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {downloadStatus === "INIT" &&
                    <>
                        <p className="headline-small">{progress < 100 ? 'Baixando suas peças...' : 'Feito!'}</p>
                        <ProgressBar progress={progress} />
                        <p className="title-small">{Math.round(progress) + "%"}</p>
                    </>
                }
            </ModalContent>

            <FeedbackModal
                open={isOpenFeebackModal}
                type={downloadStatus}
                feedbackText={"Baixado com sucesso"}
                feedbackSubtext={feedbackError}
                handleClose={() => { window.location.reload() }} />

            {feedbackError &&
                <FeedbackModal
                    open={isOpenFeebackModal}
                    type={downloadStatus}
                    feedbackText={"Erro no download"}
                    feedbackSubtext={feedbackError}
                    handleClose={() => { window.location.reload() }} />}

        </DialogModal>
    )
}

export default DownloadModal;