import api from './api';

export const getJobWithBlobInfos = async (payload) => {
    try {
        const response = await api.post('/Job/GetJobWithBlobInfos/', payload);
        return response.data;
    } catch (error) {
        console.error('Error getting job:', error.response?.data.description);
        // throw error;
    }
};

export const getJobWithBudgetStatus = async (payload) => {
    try {
        const response = await api.post('/Job/GetJobWithBudgetStatus', payload)
        return response.data;
    } catch (error) {
        console.error('Error getting job:', error.response?.data.description);
        // throw error;
    }
};

export const getJobsWithBudgetStatus = async () => {
    try {
        const response = await api.get('/Job/GetJobsWithBudgetStatus')
        return response.data;
    } catch (error) {
        console.error('Error getting job:', error.response?.data.description);
        // throw error;
    }
};
