import React, { useEffect, useState } from "react";
import { IconButton, Switch, Tooltip, Chip } from '@mui/material';
import { FileDownloadOutlined, DeleteOutlined, ContentCopyOutlined } from '@mui/icons-material';
import { VersionRow, Summary, VersionInfo, ActionButtons, Details } from './style'
import BudgetTerms from "../BudgetTerms/BudgetTerms";
import QuotationSummary from "../QuotationSummary/QuotationSummary";
import CustomButton from "../CustomButton/CustomButton"
import DeleteVersionModal from "../Modal/DeleteVersionModal/DeleteVersionModal";
import SendVersionFeedback from "../Modal/SendVersionFeedback/SendVersionFeedback";
import { sendVersionEmail, setActiveBudget, createBudgetCopy, downloadPDFVersion } from "../../api/budgetApi";
import { downloadPDFFromRequest } from "../../utils/methods";

const BudgetVersionAccordion = ({ job, version, setOpenBackdrop, openBackdrop }) => {
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openFeedback, setOpenFeedback] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [termsOrdered, setTermsOrdered] = useState([]);

    useEffect(() => {
        if (version) {
            const orderedList = version.terms.sort((a, b) => a.position - b.position)
            setTermsOrdered(orderedList)
        }
    }, [version])

    const handleOpenDeleteModal = (e) => {
        e.preventDefault()
        setOpenDeleteModal(true);
    }

    const handleSendVersionEmail = async (e) => {
        setOpenBackdrop(true);
        var formData = {
            "jobId": job.jobId,
            "budgetId": version.budgetVersionId,
            "clientEmail": job.clientEmail,
            "clientName": job.clientName
        }

        var message = await sendVersionEmail(formData);
        if (message === 'Email enviado com sucesso!') {
            setOpenBackdrop(false);
            setOpenFeedback(true);
            handleSetActiveBudget();
        }
    }

    const handleSetActiveBudget = async (e) => {
        setOpenBackdrop(true);

        var formData = { "jobId": job.jobId, "budgeVersionId": version.budgetVersionId, }

        var message = await setActiveBudget(formData);
        if (message === 'Alteração realizada com sucesso!') {
            setOpenBackdrop(false);
            window.location.reload();
        }
    }

    const handleDownloadPDFVersion = async () => {
        setOpenBackdrop(true);
        var formData = {
            jobId: job.jobId,
            budgetId: version.budgetVersionId
        }

        var filename = `${job.companyName.replace(" ", "")}-orcamento-V${version.version}.pdf`
        var message = await downloadPDFVersion(formData)
        if (message) {
            downloadPDFFromRequest(message, filename)
            setOpenBackdrop(false);
        }
    }

    return (
        <VersionRow variant="outlined" expanded={isExpanded}>
            <Summary
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <VersionInfo onClick={() => { setIsExpanded(!isExpanded) }}>
                    <p className='title-small'>Versão {version.version}</p>|
                    <p className='title-small'>{version.createdAt}</p>
                    <Chip className='title-price' variant="outlined" size="small" color="success" label={`R$ ${version.due.toLocaleString('pt-BR')}`} />
                </VersionInfo>

                <ActionButtons>
                    {version.version > 1 &&
                        <Tooltip arrow title={'Deletar versão'} component="div">
                            <IconButton aria-label="delete" onClick={handleOpenDeleteModal}>
                                <DeleteOutlined />
                            </IconButton>
                        </Tooltip>}
                    <Tooltip arrow title={'Baixar versão'} component="div">
                        <IconButton aria-label="download" onClick={handleDownloadPDFVersion}>
                            <FileDownloadOutlined />
                        </IconButton>
                    </Tooltip>
                    <Tooltip arrow title={'Ativar versão'} component="div">
                        <Switch checked={version.isActive} color="success" onChange={handleSetActiveBudget} />
                    </Tooltip>
                </ActionButtons>
            </Summary>
            <Details>
                <QuotationSummary summary={version.budgetResume} due={version.due} budgetVersionId={version.budgetVersionId} />
                <BudgetTerms terms={termsOrdered} budgetVersionId={version.budgetVersionId} />
                <CustomButton onClick={handleSendVersionEmail} variant={"contained"} sx={{ alignSelf: "end" }}>
                    <span className="title-small">Enviar versão por e-mail</span>
                </CustomButton>
            </Details>

            {openFeedback && <SendVersionFeedback open={openFeedback} handleClose={() => { setOpenFeedback(false) }} />}
            {openDeleteModal && <DeleteVersionModal version={version.version} budgetVersionId={version.budgetVersionId} open={openDeleteModal} handleClose={() => { setOpenDeleteModal(false) }} />}
        </VersionRow>
    )
}

export default BudgetVersionAccordion