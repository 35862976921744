export const isExpired = (token) => {
    if (token) {
        var isExpired = false;
        var dateNow = new Date();

        if (token.exp * 1000 < dateNow.getTime())
            isExpired = true;

        return isExpired
    } else {
        return false
    }
}
export const checkToken = () => {
    if (isExpired(getCookie("jwt__token")) ||
        getCookie("jwt__token") === null ||
        getCookie("jwt__token") === ""
    ) {
        window.location.href = "/"
    }
}

export const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
        const [key, value] = cookie.split("=");
        if (key === name) {
            return value;
        }
    }
    return null;
}

export const setCookie = (name, value, hours) => {
    const date = new Date();
    date.setTime(date.getTime() + hours * 60 * 60 * 1000); // Convert hours to milliseconds
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/;`;
}


export const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export const downloadPDFFromRequest = (blob, filename) => {
    const file = new Blob([blob], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = filename;

    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(fileURL);
    link.remove();
}


export const downloadFileFromRequest = (message, blob, filename = false) => {
    const url = window.URL.createObjectURL(new Blob([message]));
    const link = document.createElement('a');
    link.href = url;

    if (filename) {
        link.setAttribute('download', filename);
    } else {
        link.setAttribute('download', blob?.name);
    }

    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
}

