import React, { useState } from "react";
import { AssetRow } from "../JobBody/style";
import { IconButton } from '@mui/material';
import { VerticalAlignBottom } from '@mui/icons-material';
import DeleteAssetModal from '../Modal/DeleteAssetModal/DeleteAssetModal';
import { useParams } from "react-router-dom";
import { downloadFileFromRequest } from "../../utils/methods";
import { downloadAsset } from "../../api/blobStorage";

const JobAssetRow = ({ jobId, blob }) => {
    const { companyName } = useParams();
    const [openDeleteAsset, setOpenDeleteAsset] = useState(false);

    const handleDownloadBlob = async () => {
        var formData = {
            "blobInfoId": blob.id,
            "jobId": jobId,
            // "companyName": window.localStorage.getItem("companyName")
            // "companyId": window.localStorage.getItem("companyName")
        }

        var message = await downloadAsset(formData)
        if(message){
            downloadFileFromRequest(message, blob)
        }
    }

    return (
        <AssetRow>
            <span className='label-large'>{blob.name}</span>
            <div className='action-row'>
                <IconButton onClick={handleDownloadBlob}>
                    <VerticalAlignBottom fontSize='small' />
                </IconButton>
                {/* <IconButton onClick={handleDeleteAsset}>
                    <DeleteOutlined fontSize='small' />
                </IconButton> */}
            </div>
            <DeleteAssetModal open={openDeleteAsset} handleClose={() => { setOpenDeleteAsset(false) }} />
        </AssetRow>
    )
}
export default JobAssetRow;