import React, { useState } from "react";
import { IconButton } from "@mui/material";
import CustomButton from "../../CustomButton/CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import { DialogModal, ModalTitle, ModalContent, ModalActions } from "../TermModal/style"
import BudgetCalculator from "../../BudgetCalculator/BudgetCalculator";
import Backdrop from "../../Backdrop/Backdrop";
import { submitUpdatedBudget, updateBudgetFormatPersonalization } from '../../../api/budgetApi'

const EditBudgetSummary = ({ open, handleClose, summary, budgetVersionId, format }) => {
    const [updatedPayload, setUpdatedPayload] = useState(null)
    const [isLoading, setIsLoading] = useState(false);

    const handleSave = async () => {
        setIsLoading(true);
        const data = await submitUpdatedBudget(updatedPayload);
        if (data.token) {
            await handleAddToBudget(data.token);
        }
    }

    const handleAddToBudget = async (token) => {
        const message = await updateBudgetFormatPersonalization(token, budgetVersionId);
        if (message === 'Atualização de budget realizada com sucesso!') {
            setIsLoading(false);
            window.location.reload();
        }
    };

    return (
        <DialogModal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ minWidth: '450px', gap: '10px' }}
        >
            <ModalTitle className="title-large">
                Editar cotação
            </ModalTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000"
                })}
            >
                <CloseIcon />
            </IconButton>
            <ModalContent>
                <BudgetCalculator summary={summary} setUpdatedPayload={setUpdatedPayload} />
            </ModalContent>

            <ModalActions>
                <CustomButton sx={{ minWidth: '50%' }} onClick={handleSave} variant={"contained"}>
                    <span className="title-small">
                        Salvar
                    </span>
                </CustomButton>
            </ModalActions>

            {<Backdrop open={isLoading} handleClose={() => { setIsLoading(false) }} />}
        </DialogModal>
    )
}

export default EditBudgetSummary;