import api from './api';

export const downloadAsset = async (payload) => {
    try {
        const response = await api.post('/BlobStorage/download-asset/', payload, {
            responseType: 'blob'
        });
        return response.data;
    } catch (error) {
        console.error('Error downloading assets:', error.response?.data.description);
        // throw error;
    }
};