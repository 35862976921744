import axios from "axios";
import { getCookie } from "../utils/methods";

if (getCookie("jwt__token") == null && window.location.pathname !== "/") {
  window.location.href = "/"
}

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: { Authorization: `Bearer ${getCookie('jwt__token')}` },
});


export default api;