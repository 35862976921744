import React, { useContext, useEffect, useState } from "react";
import { Container, PageHeader } from "./style";
import JobTable from "../../components/JobTable/JobTable";
import { EditOutlined, ArrowBack } from "@mui/icons-material"
import { IconButton, CircularProgress } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import api from "../../api/api";
import { CompanyContext } from "../../contexts/CompanyContext";
import { checkToken } from "../../utils/methods";
import { StatusProvider } from "../../contexts/StatusContext";
import EditCompanyModal from "../../components/Modal/EditCompanyModal/EditCompanyModal";
import CustomButton from "../../components/CustomButton/CustomButton";
import colors from "../../utils/colors.json"
import ManageAccessModal from "../../components/Modal/ManageAccessModal/ManageAccessModal";

const JobsListPage = () => {
    const [jobs, setJobs] = useState([]);
    const { getIdFromName, companyList } = useContext(CompanyContext);
    const { companyName } = useParams();
    const [requestStatus, setRequestStatus] = useState("INITIAL")
    const [openEditCompanyModal, setOpenEditCompanyModal] = useState(false)
    const [openManageAccess, setOpenManageAccess] = useState(false)
    const [companyId, setCompanyId] = useState(null)

    useEffect(() => {
        checkToken();
        if (companyList.length > 0) {
            var id = getIdFromName(companyName);
            var jobsFormCompany = '/Job/GetJobsByCompany/';
            var formData = { companyId: id }
            setCompanyId(id)

            api.post(jobsFormCompany, formData).then((response) => {
                return response.data;
            }).then((data) => {
                setRequestStatus("LOADED")
                setJobs(data)
            }).catch((error) => {
                if (error.status === 404) {
                    setRequestStatus("NOT FOUND")
                }
                if (error.code === "ERR_NETWORK") {
                    window.location.href = "/"
                }
            })
        }
    }, [companyList])

    const handleOpenEditCompanyModal = () => {
        setOpenEditCompanyModal(true)
    }

    return (
        <StatusProvider>
            <Container>
                <PageHeader>
                    <Link to={"/empresas"} className="title-small">
                        <ArrowBack /> Voltar
                    </Link>
                </PageHeader>

                <PageHeader>
                    <div className="company-title">
                        <p className="headline-small">{companyName}</p>
                        <IconButton onClick={handleOpenEditCompanyModal}>
                            <EditOutlined sx={{ color: "#000" }} />
                        </IconButton>
                    </div>
                    <CustomButton onClick={() => { setOpenManageAccess(true) }} variant={"contained"} sx={{ backgroundColor: colors.dark_forest }}>
                        <span className="title-small">Gerenciar acessos</span>
                    </CustomButton>
                </PageHeader>

                {requestStatus === "LOADED" &&
                    <JobTable jobs={jobs} companyName={companyName} />}
                {requestStatus === "NOT FOUND" && <p className="title-medium">Ah, ainda não existem jobs.</p>}
                {requestStatus === "INITIAL" && <div className="loading-container">
                    <CircularProgress color="inherit" />
                    <p className="title-medium">Carregando...</p>
                </div>}
            </Container>

            <EditCompanyModal
                companyData={{
                    id: getIdFromName(companyName),
                    companyName: companyName
                }}
                open={openEditCompanyModal}
                handleClose={() => { setOpenEditCompanyModal(false) }}
            />
            {openManageAccess && <ManageAccessModal companyId={companyId} open={openManageAccess} handleClose={() => { setOpenManageAccess(false) }} />}
        </StatusProvider>
    )
}

export default JobsListPage;