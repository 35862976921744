import React, { useState } from 'react';
import { BudgetContainer, VersionsList, HeaderSection } from './style'
import CustomButton from '../CustomButton/CustomButton';
import SuccessSnackBar from '../SuccessSnackBar/SuccessSnackBar';
import RequestSendModal from '../Modal/RequestSendModal/RequestSendModal';
import JobAssetRow from '../JobAssetRow/JobAssetRow';

const JobBody = ({ job }) => {
    const [open, setOpen] = useState(false);
    const [openRequestSend, setOpenRequestSend] = useState(false);

    const handleRequestSend = () => {
        setOpenRequestSend(true);
    }

    return (
        <BudgetContainer variant='outlined'>
            <HeaderSection>
                <div className='title-container'><p p='title-small'>Assets</p></div>
                {job && job.blob.length > 0 && <div className='header-right'>
                    <CustomButton variant="outlined" onClick={handleRequestSend}>
                        <span className='title-small'>Solicitar reenvio</span>
                    </CustomButton>
                </div>
                }
            </HeaderSection>

            <VersionsList>
                {job && job.blob.length === 0 && <CustomButton
                    variant="contained"
                    onClick={handleRequestSend}
                    sx={{ width: 'fit-content', alignSelf: 'center', padding: '10px 40px' }}
                >
                    <span className='title-small'>Solicitar envio dos assets</span>
                </CustomButton>}

                {job && job.blob.map((blob, i) => (
                    <JobAssetRow jobId={job.id} blob={blob} key={blob.id} id={blob.id} />
                ))}
            </VersionsList>

            <RequestSendModal job={job && job} resend={job && job.blob.length > 0} open={openRequestSend} handleClose={() => { setOpenRequestSend(false) }} />

            <SuccessSnackBar open={open} setOpen={setOpen} />
        </BudgetContainer>
    );
};

export default JobBody;
