import styled, { css } from 'styled-components';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TableContainer, Paper } from '@mui/material';
import colors from "../../utils/colors.json"

export const StyledTableCellHead = styled(TableCell)`
  &:first-of-type {
        border-left: none !important;
        border-right: none !important;
  }

  ${(props) => css`
              ${props.sx}  
          `}

  background-color: ${colors.carbon};
  color: white !important;
  border-left: 1.5px solid ${colors["light_gray"]} !important;
  font-weight: bold;
  padding: 10px 20px !important;

  .flex-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${props => props.variant === 'no-outline' && `
      border-left: none !important;
  `}
`;

export const StyledTableCell = styled(TableCell)`
  &:last-child{
    border-right: none !important;
  }

  padding: 12px 20px !important;
  border-bottom: none !important;
  border-right: 1.5px solid ${colors["light_gray"]} !important;

  ${props => props.variant === 'no-outline' && `
      border-right: none !important;
  `}

  ${(props) => css`
              ${props.sx}  
          `}
`;

export const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  border-bottom: 1.5px solid ${colors["light_gray"]} !important;

  &:hover{
    background-color: ${colors.light_gray_2};
  }

  &:nth-last-child(1){
    border-bottom: none !important;
  }
  
  ${(props) => css`
              ${props.sx}  
          `}
  
`;

export const StyledTableContainer = ({ children, variant }) => {
  return (
    <TableContainer
      component={Paper}
      variant={'outlined'}
      elevation={0}
      sx={{ borderRadius: 3, borderColor: colors.light_gray, backgroundColor: 'transparent' }}
    >
      {children}
    </TableContainer>
  )
}