import api from "./api";

export const addUserToCompany = async () => {
  var companyData = {
    "companyId": 1,
    "userEmail": "andrefalcaov8@gmail.com"
  }


  try {
    const response = await api.post("/Client/AddClientToCompany", companyData)
    return response.data;
  }
  catch (error) {
    console.error('Error submitting updated budget:', error);
    throw error;
  }
}

export const logout = async () => {
  try {
      const response = await api.post('/Authentication/Logout', '');
      return response;
  } catch (error) {
      console.error('Error getting job:', error.response?.data.description);
      // throw error;
  }
};
