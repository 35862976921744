import React, { useState } from 'react';
import { StyledTableCellHead, StyledTableContainer } from '../DefaultTable/DefaultTable';
import { Table, TableHead, TableRow, TableBody } from '@mui/material';
import ClientAccessRow from '../ClientAccessRow/ClientAccessRow';
import colors from "../../utils/colors.json"

const ClientAccessTable = ({ clientsList, getAccessList }) => {
    return (
        <StyledTableContainer>
            <Table>
                <TableHead>
                    <TableRow >
                        <StyledTableCellHead variant='outline'
                            sx={{ color: `${colors.noir} !important`, padding: '5px 20px !important', width: '25%', backgroundColor: colors.light_gray_2 }}>
                            <div className='flex-row'>
                                <p className={"title-medium"}>Nome</p>
                            </div>
                        </StyledTableCellHead >
                        <StyledTableCellHead variant='outline'
                            sx={{ color: `${colors.noir} !important`, padding: '5px 20px !important', width: '30%', backgroundColor: colors.light_gray_2 }}>
                            <div className='flex-row'>
                                <p className={"title-medium"}>E-mail</p>
                            </div>
                        </StyledTableCellHead>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {clientsList?.map((client, i) => {
                        if (client.isActive) {
                            return <ClientAccessRow getAccessList={getAccessList} key={i} client={client} />
                        }
                    })}
                </TableBody>
            </Table>
        </StyledTableContainer>
    )
}

export default ClientAccessTable;