import React, { useState, useEffect } from "react";
import { Container, PageHeader } from "./style";
import { ArrowBack } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom"
import CustomButton from "../../components/CustomButton/CustomButton";
import { checkToken } from "../../utils/methods";
import { VerticalAlignBottom } from '@mui/icons-material';
import axios from "axios";
import { getJobWithBlobInfos } from "../../api/jobApi"
import { downloadFileFromRequest } from "../../utils/methods";
import DownloadModal from "../../components/Modal/DownloadModal/DownloadModal";

const PreviewPage = () => {
    const [job, setJob] = useState(null);
    const { jobId } = useParams();
    const [capeData, setCapeData] = useState()
    const [downloadProgress, setDownloadProgress] = useState(0)
    const [downloadModal, setDownloadModal] = useState(false);
    const [errorStatus, setErrorStatus] = useState(false);

    useEffect(() => {
        checkToken();
        getJobInfos()
        getCapeData();
    }, [])

    const handleDownload = () => {
        setDownloadModal(true);
        axios.get(capeData.downloadLink, {
            responseType: 'blob',
            onDownloadProgress: progressEvent => {
                setDownloadProgress(progressEvent.progress * 100);
            }
        },)
            .then((response) => {
                var filename = `${job.name}-${job.companyName.replace(" ", "")}-replica.zip`.toLowerCase()
                downloadFileFromRequest(response.data, false, filename)
            })
            .catch((error) => {
                setErrorStatus("Infelizmente ocorreu um erro ao baixar suas peças." + error?.response?.data?.errors[""][0])
            });
    }

    const getCapeData = () => {
        axios.get("https://storage.bycape.io/account-221/fixed/DownloadsPreviews_Feed.json")
            .then(response => response.data)
            .then(data => {
                const campaign = data.filter((c) => c.replicaPortalId === jobId)
                if (campaign.length > 0) {
                    setCapeData(campaign[0])
                }
            })
            .catch(error => console.error('Error fetching JSON:', error));
    }

    const getJobInfos = async () => {
        var formData = { "jobId": jobId }
        try {
            var message = await getJobWithBlobInfos(formData)
            if (message) { setJob(message) }
        }
        catch (error) {
            if (error.status === 404) {
                console.log(error)
            } else {
                window.location.href = "/"
            }
        }
    }

    useEffect(() => {
        console.log(downloadProgress)
    }, [downloadProgress])

    return (
        <Container>
            <PageHeader>
                <Link to={`/empresas/`} className="title-small">
                    <ArrowBack /> Voltar
                </Link>
                <CustomButton endIcon={<VerticalAlignBottom />} variant="outlined" onClick={handleDownload}>
                    <span className='title-small'> Baixar peças </span>
                </CustomButton>
            </PageHeader>

            <DownloadModal
                open={downloadModal}
                handleClose={() => { setDownloadModal(false) }}
                progress={downloadProgress}
                error={errorStatus}
            />

            <iframe style={{ border: "none" }}
                width="100%"
                height="100%"
                src={capeData?.previewLink} allowfullscreen>
            </iframe>
        </Container>
    )
}

export default PreviewPage;