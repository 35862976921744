import React, { useState, useEffect } from "react";
import { Container, PageHeader } from "./style";
import { ArrowBack } from "@mui/icons-material";
import BudgetHeader from "../../components/BudgetHeader/BudgetHeader";
import { Link, useParams } from "react-router-dom"
import BudgetBody from "../../components/BudgetBody/BudgetBody";
import DeleteBudgetAlertModal from "../../components/Modal/DeleteBudgetAlertModal/DeleteBudgetAlertModal";
import { getJobWithBudgetStatus } from "../../api/jobApi";
import { Skeleton } from "@mui/material";
import { checkToken } from "../../utils/methods"

const BudgetPage = () => {
    const [openModalDeleteBudget, setOpenModalDeleteBudget] = useState(false);
    const [budget, setBudget] = useState(null);
    const { id } = useParams()

    const handleOpenDeleteModal = () => {
        setOpenModalDeleteBudget(true);
    }

    const handleGetJobWithBudgetStatus = async () => {
        checkToken();
        var formData = { jobId: id }
        var message = await getJobWithBudgetStatus(formData)
        if (message) {
            setBudget(message)
        }
    }

    useEffect(() => {
        handleGetJobWithBudgetStatus()
    }, [])

    return (<>
        {budget ?
            <Container>
                <PageHeader>
                    <Link to={"/budgets"} className="title-small">
                        <ArrowBack /> Voltar
                    </Link>

                    <Link className="title-small" onClick={handleOpenDeleteModal}>
                        Deletar Budget
                    </Link>
                </PageHeader>
                <BudgetHeader budget={budget} />
                <BudgetBody budget={budget} />
                <DeleteBudgetAlertModal
                    open={openModalDeleteBudget}
                    budgetId={budget.jobId}
                    budgetName={budget.name}
                    handleClose={() => { setOpenModalDeleteBudget(false) }}
                />
            </Container> : <Container>
                <PageHeader>
                    <Link to={"/budgets"} className="title-small">
                        <ArrowBack /> Voltar
                    </Link>

                    <Link className="title-small" onClick={handleOpenDeleteModal}>
                        Deletar Budget
                    </Link>
                </PageHeader>
                <Skeleton variant="rounded" width={"100%"} height={"10%"} />
                <Skeleton variant="rounded" width={"100%"} height={"15%"} />
            </Container>}
    </>
    )
}

export default BudgetPage;