
import api from './api';

export const getFormatsAndPrices = async () => {
    try {
        const response = await api.get("/Formats/formats");
        return response.data;
    } catch (error) {
        console.error('Error getting formats:', error.response?.data.description);
        // throw error;
    }
};