
import api from './api';

export const getBudgetVersions = async (payload) => {
    try {
        const response = await api.post('/Budget/GetBudgetVersionsByBudget', payload);
        return response.data;
    } catch (error) {
        console.error('Error getting budget versions:', error.response?.data.description);
        // throw error;
    }
};

export const createNewBudgetVersion = async (payload) => {
    try {
        const response = await api.post('/Budget/CreateNewBudgetVersion', payload)
        return response.data;
    } catch (error) {
        console.error('Error getting budget versions:', error.response?.data.description);
        // throw error;
    }
};

export const submitUpdatedBudget = async (payload) => {
    try {
        const response = await api.post('/Budget/SubmitUpdatedBudget', payload);
        return response.data;
    } catch (error) {
        console.error('Error submitting updated budget:', error.response?.data.description);
        // throw error;
    }
};

export const updateBudgetFormatPersonalization = async (token, budgetId) => {
    try {
        const formData = { token, budgetId };
        const response = await api.post('/FormatPersonalization/UpdateBudgetVersionFormatPersonalizations', formData);
        return response.data;
    } catch (error) {
        console.error('Error updating budget format:', error.response?.data.description);
        // throw error;
    }
};

export const sendVersionEmail = async (payload) => {
    try {
        const response = await api.post('/Budget/SendBudgetVersionEmail/', payload)
        return response.data;
    } catch (error) {
        console.error('Error sending budget email:', error.response?.data.description);
        // throw error;
    }
};

export const setActiveBudget = async (payload) => {
    try {
        const response = await api.post('/Budget/SetActiveBudget/', payload)
        return response.data;
    } catch (error) {
        console.error('Error activating budget:', error.response?.data.description);
        // throw error;
    }
};

export const createBudgetCopy = async (payload) => {
    try {
        const response = await api.post('/Budget/CreateBudgetCopy/', payload)
        return response.data;
    } catch (error) {
        console.error('Error activating budget:', error.response?.data.description);
        // throw error;
    }
};

export const downloadPDFVersion = async (payload) => {
    try {
        const response = await api.post('/Budget/DownloadBudgetVersionPdf', payload, { responseType: 'blob' })
        return response.data;
    } catch (error) {
        console.error('Error activating budget:', error.response?.data.description);
        // throw error;
    }
};