import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { styled } from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import colors from "../../utils/colors.json"

const CustomTextField = styled(TextField)(({ theme, variant }) => ({
    "& .MuiInputBase-root::before": {
        display: "none"
    },
    "& fieldset": {
        borderColor: "transparent",
    },
    "& .MuiOutlinedInput-root": {
        ...(variant === "outlined" && {
            borderRadius: 50,
            boxShadow: 'none',
            backgroundColor: "transparent",
            border: "1.5px solid #00000026",
            "&:hover fieldset": {
                borderColor: "#00000026",
                border: "1.5px solid #00000026",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#00000026",
                border: "1.5px solid #00000026",
            },
        }),
    },
    "& .MuiFilledInput-root": {
        ...(variant === "filled" && {
            borderRadius: 50,
            backgroundColor: "#f5f5f5",
            "&:hover": {
                backgroundColor: "#e0e0e0",
                borderColor: "#00000026",
            },
            "&.Mui-focused": {
                backgroundColor: "#ddd",
                borderColor: "#00000026",
            },
            "&:hover fieldset": {
                borderColor: "#00000026",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#00000026",
            },
        }),
    },
    "& .MuiInputBase-input": {
        padding: "8px 14px",
    },
}));


const SearchBar = ({ placeholder, onChange, variant, sx }) => {
    return (
        <CustomTextField
            sx={sx}
            onChange={onChange}
            className='body-medium'
            variant={variant}
            placeholder={placeholder}
            slotProps={{
                input: {
                    endAdornment: (
                        <InputAdornment position="end" sx={{ backgroundColor: '#434343', borderRadius: 9999, padding: '10px 5px' }}>
                            <SearchIcon sx={{ color: colors.white }} />
                        </InputAdornment>
                    ),
                }
            }}
        />
    );
};

export default SearchBar;
