import React from "react";
import { BarContainer } from "./style";

const ProgressBar = ({ progress }) => {
  return (
    <BarContainer progress={progress}>
      <div className={`fill ${progress === 100 && 'success'}`}></div>
    </BarContainer>
  )
}

export default ProgressBar;