import React, {useState} from "react";
import { Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions, } from "@mui/material";
import CustomButton from "../../CustomButton/CustomButton";
import api from "../../../api/api";
import Backdrop from "../../Backdrop/Backdrop";

const DeleteVersionModal = ({ open, budgetVersionId, handleClose, version }) => {
    const [ openLoading, setOpenLoading ] = useState(false)

    const handleDelete = () => {
        setOpenLoading(true);
        var deleteURL = '/Budget/DeleteBudgetVersion/' + budgetVersionId;

        api.delete(deleteURL)
            .then((response) => {
                return response.data;
            })
            .then((data) => {
                console.log(data)
                if (data === "Versão de orçamento apagada com sucesso.") {
                    setOpenLoading(false);
                    window.location.reload();
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    return (
        <Dialog
            PaperProps={{
                sx: { borderRadius: '10px', padding: '20px', gap: '20px', minWidth: '30%' }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle sx={{ padding: 0 }} className="title-large">
                Excluir versão
            </DialogTitle>
            <DialogContent sx={{ padding: 0 }}>
                <DialogContentText className="title-medium">
                    Tem certeza que deseja excluir a versão {version} ?
                </DialogContentText>
            </DialogContent>

            <DialogActions sx={{ padding: 0 }}>
                <CustomButton onClick={handleClose} variant={"outlined"} fullWidth>
                    <span className="title-small">Cancelar</span>
                </CustomButton>
                <CustomButton onClick={handleDelete} variant={"contained"} fullWidth>
                    <span className="title-small">
                        Excluir
                    </span>
                </CustomButton>
            </DialogActions>

            <Backdrop 
                open={openLoading}
                handleClose={()=>{setOpenLoading(false)}}
            />
        </Dialog>
    )
}

export default DeleteVersionModal;