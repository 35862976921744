import api from "./api";

export const editCompanyName = async (companyData) => {
    try {
        const response = await api.post("/Company/EditCompanyName", companyData)
        return response.data;
    }
    catch (error) {
        console.error('Error submitting updated budget:', error);
        throw error;
    }
}