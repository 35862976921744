import React, { useState } from 'react';
import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { StyledTableCell, StyledTableRow } from '../DefaultTable/DefaultTable';
import { IconButton, Tooltip } from '@mui/material';
import InativateUserModal from '../Modal/InativateUserModal/InativateUserModal';
import KeyIcon from '@mui/icons-material/Key';
import api from "../../api/api"
import { FeedbackModal } from '../Modal/FeedbackModal/FeedbackModal';
import Backdrop from '../Backdrop/Backdrop';

const ClientAccessRow = ({ client, getAccessList }) => {
    const [openInativateModal, setOpenInativateModal] = useState(false);
    const [openSendTokenFeedback, setOpenSendTokenFeedback] = useState(false);
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const handleResetPassword = () => {
        setOpenBackdrop(true);
        api.post('/Client/SendUserPasswordResetTokenEmail',
            {
                email: client.email,
                name: client.name
            }).then((res) => {
                if (res.status === 200) {
                    setOpenBackdrop(false);
                    setOpenSendTokenFeedback(true);
                }
            }).catch((err) => {
                console.error(err)
            })
    }

    return (
        <StyledTableRow sx={{ cursor: "default", ":hover": { backgroundColor: "transparent" } }} >
            <StyledTableCell className='body-large' variant='outline' sx={{ padding: '10px 20px !important', whiteSpace: 'nowrap' }}>
                {client.name}
            </StyledTableCell>
            <StyledTableCell className='body-large' variant='outline'
                sx={{ padding: '5px 10px 5px 20px !important', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                <p className='body-large' style={{ flexGrow: 1 }}> {client.email}</p>

                <Tooltip arrow title={'Desativar acesso'} component="div">
                    <IconButton onClick={() => { setOpenInativateModal(true) }}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip arrow title={'Reenviar token'} component="div">
                    <IconButton color={'inherit'} onClick={handleResetPassword}>
                        <KeyIcon />
                    </IconButton>
                </Tooltip>
            </StyledTableCell>

            <td style={{display: 'none'}}>
                <InativateUserModal getAccessList={getAccessList} open={openInativateModal} client={client} handleClose={() => { setOpenInativateModal(false) }} />

                <FeedbackModal
                    open={openSendTokenFeedback}
                    type={"success"}
                    feedbackText={"Email de redefinição de senha enviado!"}
                    handleClose={() => { setOpenSendTokenFeedback(false) }}
                    feedbackSubtext={""}
                />

                <Backdrop open={openBackdrop} />
            </td>
        </StyledTableRow >
    )
}

export default ClientAccessRow;