import React, { useState, useEffect } from "react";
import { Container, PageHeader } from "./style";
import BudgetsTable from "../../components/BudgetsTable/BudgetsTable";
import SearchBar from "../../components/SearchBar/SearchBar";
import { checkToken } from "../../utils/methods";
import { CircularProgress } from "@mui/material";
import { getJobsWithBudgetStatus } from "../../api/jobApi";

const BudgetsListPage = () => {
    const [budgets, setBudgets] = useState([]);
    const [filteredBudgets, setFilteredBudgets] = useState([...budgets]);
    const [requestStatus, setRequestStatus] = useState("INITIAL")

    const handleGetJobsWithBudgetStatus = async () => {
        checkToken();
        try {
            var message = await getJobsWithBudgetStatus()
            if (message) {
                setBudgets(message);
                setRequestStatus("LOADED")
            } else {
                setRequestStatus("NOT FOUND")
            }
        } catch (error) {
            if (error.status === 404) {
                console.log(error)
            } else {
                window.location.href = "/"
            }
        }
    }

    useEffect(() => {
        handleGetJobsWithBudgetStatus()
    }, [])

    useEffect(() => {
        setFilteredBudgets([...budgets])
    }, [budgets])

    const filterBudgetList = (e) => {
        const searchTerm = e.target.value.toLowerCase();

        const filteredList = budgets.filter((item) =>
            item.jobDescription?.toLowerCase().includes(searchTerm) ||
            item.companyName?.toLowerCase().includes(searchTerm) ||
            item.clientEmail?.toLowerCase().includes(searchTerm)
        );

        if (e.target.value === '' || e.target.value === undefined) {
            setFilteredBudgets(budgets);
        } else {
            setFilteredBudgets(filteredList)
        }
    }

    return (
        <Container>
            <PageHeader>
                <p className="headline-small"><u>Budgets</u></p>
                <SearchBar sx={{ width: "30%" }} variant={'outlined'} onChange={filterBudgetList} placeholder="Pesquise um cliente ou budget..." />
            </PageHeader>
            {requestStatus === "LOADED" &&
                <BudgetsTable
                    filteredBudgets={filteredBudgets}
                    setFilteredBudgets={setFilteredBudgets}
                    budgets={budgets}
                />}
            {requestStatus === "NOT FOUND" && <p className="title-medium">Ah, ainda não existem budgets.</p>}
            {requestStatus === "INITIAL" && <div className="loading-container">
                <CircularProgress color="inherit" />
                <p className="title-medium">Carregando...</p>
            </div>}
        </Container>
    )
}

export default BudgetsListPage;